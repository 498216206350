<template>
  <!-- Footer -->
  <footer class="my-5 mx-auto d-flex footer">

    <!-- Details -->
    <div class="d-none d-sm-block col me-4">
      <h1>Milktoyou</h1>
      <small class="color-fg">I am a well established, widely known and loved milkman delivering in the heart of Ellesmere Port.
      </small>
      <div class="mt-4 align-self-start d-flex align-items-center">
        <!-- <a target="_blank" href="https://apps.apple.com/gb/app/the-island-house/id6450675410" class="btn btn-secondary me-2">
          <img alt="appstore" class="me-2" style="height:20px;" src="../assets/img/app-store.webp">
          <span>Apple Store</span>
        </a>
        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.orderopia.theislandhouse" class="btn btn-secondary">
          <img alt="googleplay" class="me-2" style="height:20px;" src="../assets/img/google-play.webp">
          <span>Play Store</span>
        </a> -->

      </div>
    </div>

    <!-- Site Map -->
    <div class="ms-4 ms-sm-0 me-4 sitemap col d-flex flex-column">
      <h3 class="mb-3">SITEMAP</h3>
      <router-link to="">Home</router-link>
      <router-link to="/our-supplies">Our Supplies</router-link>
      <router-link to="/order">Order Now</router-link>
      <router-link to="/delivery-area">Delivery Area</router-link>
      <router-link to="/contact">Contact</router-link>
      <!-- <router-link to="/returns-policy">Legal</router-link> -->

    </div>
    <!-- Social Media -->
    <div class="social-media-container me-4 me-sm-0 col-auto d-flex">

      <!-- <a href="https://www.tiktok.com/@the.island.house?_t=8pzDAP0tHe5&_r=1" class="btn-icon">
        <i class="bi bi-tiktok"></i>
      </a>
      <a href="https://www.facebook.com/theislandhouseuk" class="btn-icon me-3">
        <i class="bi bi-facebook"></i>
      </a>
      <a href="https://www.instagram.com/theislandhousetakeaway/?hl=en" class="btn-icon">
        <i class="bi bi-instagram"></i>
      </a> -->
    </div>

  </footer>
</template>

<script>
export default {
  name: 'RuggedSeparator',
  props: {
    isTop: {
      type: Boolean,
      default: true
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  max-width: 800px;
  width: 100%;
}

.btn-icon {
  font-size:28px;
}

.sitemap a {
  color:var(--foreground);
  height: 30px;
}

@media (max-width:800px) {



  .social-media-container {
    flex-direction: column;
  }

  .social-media-container * {
    margin-bottom: 10px;
  }



}
</style>
