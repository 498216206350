import { createApp } from 'vue';
import App from './App.vue';
import { createHead } from '@vueuse/head';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './assets/js/store.js';
import router from './router';

// Import Vue Google Maps
import VueGoogleMaps from '@fawmi/vue-google-maps';

const head = createHead();

const app = createApp(App);

app
  .use(head)
  .use(store)
  .use(router)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyDFxLyzzF-2xcQ-GjwBZv2rMySYMsn_dPw', // Replace with your actual Google Maps API key
      libraries: 'places', // Add any other libraries you need, such as 'geometry' or 'places'
    },
  });

app.mount('#app');
